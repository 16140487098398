import React from "react";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import PqtPagination from "./../../PqtPagination";
import { dateToTimezone, empty } from "./../../../utils";
import moment from "moment-timezone";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import numeral from "numeral";
import { Link } from "react-router-dom";

const Invoices = React.memo((props) => {
    const handleDelete = (id) => {
        props.showDeleteModal();
        props.setIdForDelete(id);
    };

    const getValue = (field) => {
        if (empty(field)) {
            return "";
        }

        return field;
    };

    const handleEdit = (id) => {
        let invoice = null;
        for (const invoiceObj of props.invoices) {
            if (parseInt(invoiceObj.id) === parseInt(id)) {
                invoice = invoiceObj;
                break;
            }
        }

        if (invoice == null) {
            return;
        }

        props.setPoNumber(invoice.po_number);
        props.setField("id", getValue(invoice.id));
        props.setField("invoice_number", getValue(invoice.invoice_number));
        props.setField("invoice_date", getValue(invoice.invoice_date));
        props.setField("vendor_id", getValue(invoice.vendor_id));
        props.setField("po_match", getValue(invoice.po_match));
        props.setField("po_number", getValue(invoice.po_number));
        props.setField("notes", getValue(invoice.notes));
        props.setField("memo", getValue(invoice.memo));
        props.setField("amount", getValue(invoice.amount));
        props.setField(
            "ap_invoice_account_id",
            getValue(invoice.ap_invoice_account_id)
        );
        props.setAuditHistory(invoice.auditHistory);
        props.setPoFieldDisabled(false);
        props.setSendEmail(0);
        props.setEmails("");
        props.setSendShortPayEmail(0);
        props.setShortPayEmails("");
        props.showFormModal();
    };

    const renderInvoices = () => {
        if (props.fetchingInvoices) {
            return (
                <tr>
                    <td colSpan="18">
                        <i className="fas fa-circle-notch fa-spin" /> Loading
                        data...
                    </td>
                </tr>
            );
        }

        if (props.invoices.length === 0) {
            return (
                <tr>
                    <td colSpan="18">No Results</td>
                </tr>
            );
        }

        return props.invoices.map((invoice, index) => {
            let invoiceDate = "-";
            if (invoice.invoice_date != null) {
                invoiceDate = moment(invoice.invoice_date).format("MM/DD/YYYY");
            }

            let createdAt = "-";
            if (invoice.created_at != null) {
                createdAt = dateToTimezone(invoice.created_at, "MM/DD/YYYY");
            }

            let pushedAt = "-";
            if (invoice.pushed_at != null) {
                pushedAt = dateToTimezone(invoice.pushed_at + ' 12:00:00', "MM/DD/YYYY");
            }

            // let poMatch = "N/A";
            // if (parseInt(invoice.po_match) === 1) {
            //     poMatch = "Invoice and PO match";
            // } else if (parseInt(invoice.po_match) === 2) {
            //     poMatch = "Invoice and PO does not match";
            // } else if (parseInt(invoice.po_match) === 3) {
            //     poMatch = "PO does not exist";
            // } else if (parseInt(invoice.po_match) === 4) {
            //     poMatch = "Vendor does not exist";
            // }

            let posLinks = [];
            for (const invoicePo of invoice.pos) {
                posLinks.push(
                    <Link
                        to={"/purchase-order/" + invoicePo.purchase_order_id}
                        target="_blank"
                    >
                        {invoicePo.po_number}
                    </Link>
                );
                posLinks.push(", ");
            }

            if (posLinks.length > 0) {
                posLinks.pop();
            }

            return (
                <tr key={"invoice-" + index}>
                    <td>{posLinks}</td>
                    <td>{invoice.bill_number}</td>
                    <td>{invoice.vendor_name}</td>
                    <td>{invoice.invoice_number}</td>
                    <td>{invoiceDate}</td>
                    <td>{numeral(invoice.amount).format("$0,0.00")}</td>
                    {/* <td>{poMatch}</td> */}
                    <td>
                        <a
                            href={invoice.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoice.filename}
                        </a>
                    </td>
                    <td>{createdAt}</td>
                    <td>{pushedAt}</td>
                    <td>
                        <div className="actions">
                            <Tooltip
                                title="Edit Invoice"
                                position="bottom"
                                arrow
                                size="small"
                                className="action-tooltip"
                            >
                                <i
                                    className="fas fa-edit"
                                    onClick={handleEdit.bind(null, invoice.id)}
                                />
                            </Tooltip>
                            <Tooltip
                                title="Delete Invoice"
                                position="bottom"
                                arrow
                                size="small"
                                className="action-tooltip"
                            >
                                <i
                                    className="fas fa-trash"
                                    onClick={handleDelete.bind(
                                        null,
                                        invoice.id
                                    )}
                                />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const setDateFieldHandler = (name, event) => {
        let value =
            event === undefined
                ? ""
                : moment(event).tz("America/Chicago").format("YYYY-MM-DD");
        props.changeFilter(name, value, true);
    };

    const renderSearchDateField = (name) => {
        let value = null;
        for (let i = 0; i < props.filterFields.length; i++) {
            if (props.filterFields[i].name === name) {
                value = props.filterFields[i].value;
            }
        }

        return (
            <th className="search-th">
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={setDateFieldHandler.bind(this, name)}
                    value={
                        value == null || value === "0000-00-00"
                            ? ""
                            : moment(value).format("MM/DD/YYYY")
                    }
                />
            </th>
        );
    };

    const setSortHandler = (field, value) => {
        props.setSort(field, value);
    };

    const renderHeader = (name, column, className) => {
        let sortFunc = setSortHandler.bind(this, column, "asc");
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === column) {
            if (props.sort.type === "asc") {
                sortFunc = setSortHandler.bind(this, column, "desc");
                sortIcon = <i className="fas fa-sort-up" />;
            } else {
                sortFunc = setSortHandler.bind(this, column, "asc");
                sortIcon = <i className="fas fa-sort-down" />;
            }
        }

        return (
            <th className={className} onClick={sortFunc}>
                {name} {sortIcon}
            </th>
        );
    };

    const handleChangeFilter = (name, event) => {
        props.changeFilter(name, event.target.value);
    };

    const handleKey = (name, event) => {
        if (event.key === "Enter") {
            handleChangeFilter(name, event);
        }
    };

    const renderSearchField = (name) => {
        return (
            <th className="search-th">
                <input
                    type="text"
                    name={name}
                    className="search-input"
                    onKeyPress={handleKey.bind(this, name)}
                />
            </th>
        );
    };

    return (
        <div className="invoices-container">
            <div className="actions-container">
                <div className="action-buttons"></div>
                <div className="show-fields"></div>
            </div>
            <h3>Invoice List</h3>
            <div className="table-container">
                <table className="table table-sm table-hover table-striped">
                    <thead>
                        <tr>
                            {renderHeader("PO", "po_number", "th-po-number")}
                            {renderHeader(
                                "Bill",
                                "bill_number",
                                "th-bill-number"
                            )}
                            {renderHeader(
                                "Vendor",
                                "vendor_name",
                                "th-vendor-name"
                            )}
                            {renderHeader(
                                "Invoice #",
                                "invoice_number",
                                "th-invoice-number"
                            )}
                            {renderHeader(
                                "Invoice Date",
                                "invoice_date",
                                "th-invoice-date"
                            )}
                            {renderHeader("Amount", "amount", "th-amount")}
                            {/* {renderHeader(
                                "PO Match?",
                                "po_match",
                                "th-po-match"
                            )} */}
                            {renderHeader("File", "filename", "th-filename")}
                            {renderHeader(
                                "Created At",
                                "created_at",
                                "th-created-at"
                            )}
                            {renderHeader(
                                "Pushed At",
                                "pushed_at",
                                "th-pushed-at"
                            )}
                            <th style={{ width: 80, textAlign: "center" }}></th>
                        </tr>
                        <tr>
                            {renderSearchField("po_number")}
                            {renderSearchField("bill_number")}
                            {renderSearchField("vendor_name")}
                            {renderSearchField("invoice_number")}
                            {renderSearchDateField("invoice_date")}
                            {renderSearchField("amount")}
                            {/* {renderSearchField("po_match")} */}
                            {renderSearchField("filename")}
                            {renderSearchDateField("created_at")}
                            {renderSearchDateField("pushed_at")}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{renderInvoices()}</tbody>
                </table>
                <PqtPagination
                    pagination={props.pagination}
                    setActivePage={props.setActivePage}
                />
            </div>
        </div>
    );
});

export default Invoices;
