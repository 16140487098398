import Immutable from "immutable";
import * as types from "./ApDashboard.types";

const initialState = {
    id: null,
    fetching: {
        purchaseOrders: false,
        apInvoices: false,
    },
    data: {
        purchaseOrders: [],
        apInvoices: [],
    },
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 10,
    },
    sort: {
        column: "created_at",
        type: "desc",
    },
    history: null,
    delete: {
        id: null,
        isDeleting: false,
    },
    filter: {
        fields: [],
    },
    file: null,
    information: {
        fetching: false,
        vendors: [],
        accounts: [],
    },
    fields: {
        id: "",
        invoice_number: "",
        invoice_date: "",
        vendor_id: "",
        po_match: "",
        po_number: "",
        notes: "",
        memo: "",
        amount: "",
        ap_invoice_account_id: "",

        // invoice_number: "2024000909150",
        // invoice_date: "2024-11-12",
        // vendor_id: "513",
        // po_match: "1",
        // po_number: "10592",
        // notes: "Test",
        // memo: "Testing PDF upload",
        // amount: "112.2",
        // ap_invoice_account_id: "",
    },
    poNumber: "",
    // poNumber: "10492",
    activeTab: 1,
    saving: false,
    poFieldDisabled: false,
    sendEmail: 0,
    emails: "",
    sendShortPayEmail: 0,
    shortPayEmails: "",
    auditHistory: [],
    checkingPo: false,
    checkingPoMessage: "",
    creatingBill: false,
};

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_PURCHASE_ORDERS:
            return state.setIn(["fetching", "purchaseOrders"], true).toJS();

        case types.FETCH_PURCHASE_ORDERS_SUCCESS:
            return state
                .setIn(["fetching", "purchaseOrders"], false)
                .setIn(["data", "purchaseOrders"], action.payload.elements)
                .toJS();

        case types.FETCH_PURCHASE_ORDERS_ERROR:
            return state.setIn(["fetching", "purchaseOrders"], false).toJS();

        case types.FETCH_INVOICES:
            return state.setIn(["fetching", "apInvoices"], true).toJS();

        case types.FETCH_INVOICES_SUCCESS:
            return state
                .setIn(["fetching", "apInvoices"], false)
                .setIn(["data", "apInvoices"], action.payload.elements)
                .setIn(
                    ["pagination", "totalElements"],
                    action.payload.totalElements
                )
                .setIn(["pagination", "activePage"], action.payload.activePage)
                .setIn(["pagination", "totalPages"], action.payload.totalPages)
                .setIn(
                    ["pagination", "elementsPerPage"],
                    action.payload.elementsPerPage
                )
                .toJS();

        case types.FETCH_INVOICES_ERROR:
            return state.setIn(["fetching", "apInvoices"], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(["information", "fetching"], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state
                .setIn(["information", "fetching"], false)
                .setIn(["information", "vendors"], action.payload.vendors)
                .setIn(["information", "accounts"], action.payload.accounts)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(["information", "fetching"], false).toJS();

        case types.SAVE_INVOICE:
            return state.setIn(["saving"], true).toJS();

        case types.SAVE_INVOICE_SUCCESS:
            return state
                .setIn(["saving"], false)
                .setIn(["file"], null)
                .setIn(["sendEmail"], 0)
                .setIn(["emails"], "")
                .setIn(["sendShortPayEmail"], 0)
                .setIn(["shortPayEmails"], "")
                .setIn(["auditHistory"], [])
                .setIn(["fields"], {
                    id: "",
                    invoice_number: "",
                    invoice_date: "",
                    vendor_id: "",
                    po_match: "",
                    po_number: "",
                    notes: "",
                    memo: "",
                    amount: "",
                    ap_invoice_account_id: "",
                })
                .toJS();

        case types.SAVE_INVOICE_ERROR:
            return state.setIn(["saving"], false).toJS();

        case types.DELETE_INVOICE:
            return state.setIn(["delete", "isDeleting"], true).toJS();

        case types.DELETE_INVOICE_SUCCESS:
            return state
                .setIn(["delete", "isDeleting"], false)
                .setIn(["delete", "id"], null)
                .toJS();

        case types.DELETE_INVOICE_ERROR:
            return state.setIn(["delete", "isDeleting"], false).toJS();

        case types.CREATE_BILL:
            return state.setIn(["creatingBill"], true).toJS();

        case types.CREATE_BILL_SUCCESS:
            return state.setIn(["creatingBill"], false).toJS();

        case types.CREATE_BILL_ERROR:
            return state.setIn(["creatingBill"], false).toJS();

        case types.CHECK_PO:
            return state.setIn(["checkingPo"], true).toJS();

        case types.CHECK_PO_SUCCESS:
            return state
                .setIn(["checkingPo"], false)
                .setIn(["checkingPoMessage"], action.payload)
                .toJS();

        case types.CHECK_PO_ERROR:
            return state.setIn(["checkingPo"], false).toJS();

        case types.SET_SORT:
            return state.setIn(["sort"], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state
                .setIn(["pagination", "activePage"], action.payload)
                .toJS();

        case types.SET_ID_FOR_DELETE:
            return state.setIn(["delete", "id"], action.payload).toJS();

        case types.CHANGE_FILTER:
            return state
                .setIn(["pagination", "activePage"], 1)
                .updateIn(["filter", "fields"], (fields) => {
                    const index = fields.findIndex(
                        (field) => field.get("name") === action.payload.name
                    );
                    if (action.payload.value === "") {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({
                                name: action.payload.name,
                                value: action.payload.value,
                            });
                        } else {
                            return fields.set(index, {
                                name: action.payload.name,
                                value: action.payload.value,
                            });
                        }
                    }
                })
                .toJS();

        case types.SET_FILE:
            return state.setIn(["file"], action.payload).toJS();

        case types.SET_PO_NUMBER:
            return state.setIn(["poNumber"], action.payload).toJS();

        case types.SET_ACTIVE_TAB:
            return state.setIn(["activeTab"], action.payload).toJS();

        case types.SET_PO_FIELD_DISABLED:
            return state.setIn(["poFieldDisabled"], action.payload).toJS();

        case types.SET_SEND_EMAIL:
            return state.setIn(["sendEmail"], action.payload).toJS();

        case types.SET_EMAILS:
            return state.setIn(["emails"], action.payload).toJS();

        case types.SET_SEND_SHORT_PAY_EMAIL:
            return state.setIn(["sendShortPayEmail"], action.payload).toJS();

        case types.SET_SHORT_PAY_EMAILS:
            return state.setIn(["shortPayEmails"], action.payload).toJS();

        case types.SET_AUDIT_HISTORY:
            return state.setIn(["auditHistory"], action.payload).toJS();

        case types.SET_FIELD:
            return state
                .setIn(["fields", action.payload.field], action.payload.value)
                .toJS();

        case types.CLEAR_FORM:
            return state
                .setIn(["file"], null)
                .setIn(["sendEmail"], 0)
                .setIn(["emails"], "")
                .setIn(["sendShortPayEmail"], 0)
                .setIn(["shortPayEmails"], "")
                .setIn(["auditHistory"], [])
                .setIn(["fields"], {
                    id: "",
                    invoice_number: "",
                    invoice_date: "",
                    vendor_id: "",
                    po_match: "",
                    po_number: "",
                    notes: "",
                    memo: "",
                    amount: "",
                    ap_invoice_account_id: "",
                })
                .toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}
