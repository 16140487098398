import { createSelector } from "reselect";

export const getComponentState = (state) => state.apDashboard;

/* fetching */
export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);
export const getFetchingPurchaseOrders = createSelector(
    getComponentState,
    (state) => state.fetching.purchaseOrders
);

/* data */
export const getPurchaseOrdersData = createSelector(
    getComponentState,
    (state) => state.data.purchaseOrders
);

/* pagination */
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);

/* sort */
export const getSort = createSelector(getComponentState, (state) => state.sort);

/* delete */
export const getIdForDelete = createSelector(
    getComponentState,
    (state) => state.delete.id
);
export const getIsDeleting = createSelector(
    getComponentState,
    (state) => state.delete.isDeleting
);

export const getShow = createSelector(getComponentState, (state) => state.show);

export const getFilterFields = createSelector(
    getComponentState,
    (state) => state.filter.fields
);
export const getFile = createSelector(getComponentState, (state) => state.file);
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getFields = createSelector(
    getComponentState,
    (state) => state.fields
);
export const getPoNumber = createSelector(
    getComponentState,
    (state) => state.poNumber
);
export const getActiveTab = createSelector(
    getComponentState,
    (state) => state.activeTab
);
export const getSaving = createSelector(
    getComponentState,
    (state) => state.saving
);
export const getFetchingInvoices = createSelector(
    getComponentState,
    (state) => state.fetching.apInvoices
);
export const getInvoices = createSelector(
    getComponentState,
    (state) => state.data.apInvoices
);
export const getPoFieldDisabled = createSelector(
    getComponentState,
    (state) => state.poFieldDisabled
);
export const getSendEmail = createSelector(
    getComponentState,
    (state) => state.sendEmail
);
export const getEmails = createSelector(
    getComponentState,
    (state) => state.emails
);
export const getSendShortPayEmail = createSelector(
    getComponentState,
    (state) => state.sendShortPayEmail
);
export const getShortPayEmails = createSelector(
    getComponentState,
    (state) => state.shortPayEmails
);
export const getAuditHistory = createSelector(
    getComponentState,
    (state) => state.auditHistory
);
export const getCheckingPo = createSelector(
    getComponentState,
    (state) => state.checkingPo
);
export const getCheckingPoMessage = createSelector(
    getComponentState,
    (state) => state.checkingPoMessage
);
export const getCreatingBill = createSelector(
    getComponentState,
    (state) => state.creatingBill
);
