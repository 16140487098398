import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';
import { validateForm } from './../PurchaseOrder.actions';

const Form = React.memo((props) => {
    if (props.fetching || props.information.fetching) {
        return (
            <div className="purchase-order-form-container">
                <i className="fas fa-circle-notch fa-spin" /> Loading data...
            </div>
        );
    }

    const handleOnChange = (field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }
        props.setField(field, value);

        if (field === 'use_vendor_address' && value) {
            props.setField('vendor_name', props.purchaseOrder.vendor_name);
            props.setField('vendor_email', props.purchaseOrder.vendor_email);
            props.setField('vendor_address', props.purchaseOrder.vendor_address);
            props.setField('vendor_city', props.purchaseOrder.vendor_city);
            props.setField('vendor_state', props.purchaseOrder.vendor_state);
            props.setField('vendor_zip', props.purchaseOrder.vendor_zip);
        } else if (field === 'vendor_id' && value) {
            let selectedVendor = null;
            for (let i = 0; i < props.information.vendors.length; i++) {
                if (parseInt(props.information.vendors[i].id) === parseInt(value)) {
                    selectedVendor = props.information.vendors[i];
                    break;
                }
            }

            if (selectedVendor != null) {
                let nameParts = [];
                if (selectedVendor.first_name) {
                    nameParts.push(selectedVendor.first_name);
                }
                if (selectedVendor.last_name) {
                    nameParts.push(selectedVendor.last_name);
                }

                props.setField('vendor_name', nameParts.join(' '));
                props.setField('vendor_email', selectedVendor.email);
                props.setField('vendor_address', selectedVendor.address);
                props.setField('vendor_city', selectedVendor.city);
                props.setField('vendor_state', selectedVendor.state);
                props.setField('vendor_zip', selectedVendor.zip);
            }
        }
    };

    const handleUpdateItem = (index, field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }

        props.setItemField(index, field, value);
    };

    const handleUpdateItemCategory = (index, field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }

        props.setItemField(index, field, value);

        if (parseInt(value) === 8) {  // Mailing List
            props.setItemField(index, 'purchase_order_class_id', 2);    // Data
        }
        if (parseInt(value) !== 8 && parseInt(props.purchaseOrder.items[index].purchase_order_class_id) === 2) {
            props.setItemField(index, 'purchase_order_class_id', '');
        }
    };

    const handleUpdateItemClass = (index, field, event) => {
        let value = null;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        } else {
            value = event.target.value;
        }

        props.setItemField(index, field, value);

        if (parseInt(value) === 2) {  // Data
            props.setItemField(index, 'purchase_order_category_id', 8);  // Mailing List
        }
        if (parseInt(value) !== 2 && parseInt(props.purchaseOrder.items[index].purchase_order_category_id) === 8) {
            props.setItemField(index, 'purchase_order_category_id', '');
        }
    };

    const handleUpdatePrice = (index, field, event) => {
        let value = parseFloat(event.target.value);
        props.setItemField(index, field, value)
    };

    const renderCategoriesOptions = () => {
        return props.information.categories.map((category) => {
            return <option key={category.id} value={category.id}>{category.name}</option>;
        });
    };

    const renderClassesOptions = () => {
        return props.information.classes.map((item) => {
            return <option key={item.id} value={item.id}>{item.name}</option>;
        });
    };

    const handleSortUp = (index, currentPosition) => {
        currentPosition = parseInt(currentPosition);
        props.changeSortPosition(index, currentPosition - 1, 1);
    };

    const handleSortDown = (index, currentPosition) => {
        currentPosition = parseInt(currentPosition);
        props.changeSortPosition(index, currentPosition + 1, 2);
    };

    const renderItems = () => {
        const itemsElements = [];
        for (let i = 0; i < props.purchaseOrder.items.length; i++) {
            let deleteIcon = null;
            if (props.user != null && parseInt(props.user.id) === 61) {
                deleteIcon = (
                    <Tooltip title="Remove Line" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash-alt" onClick={props.removeLine.bind(this, i)} />
                    </Tooltip>
                );
            }

            let upArrow = <div className="sort-up"><i className="fas fa-sort-up" onClick={handleSortUp.bind(this, i, props.purchaseOrder.items[i].sort_position)} /></div>;
            let downArrow = <div className="sort-down"><i className="fas fa-sort-down" onClick={handleSortDown.bind(this, i, props.purchaseOrder.items[i].sort_position)} /></div>;
            if (i === 0) {
                upArrow = <div className="sort-up"></div>;
            }
            if (i === props.purchaseOrder.items.length - 1) {
                downArrow = null;
            }

            let mailingRowClass = '';
            let descriptionField = (
                <textarea
                    className="textarea-description"
                    value={props.purchaseOrder.items[i].description}
                    onChange={handleUpdateItem.bind(this, i, 'description')}
                    rows={1}
                />
            );
            if (
                parseInt(props.purchaseOrder.items[i].purchase_order_category_id) === 8 &&      // Mailing Lists
                parseInt(props.purchaseOrder.items[i].purchase_order_class_id) === 2            // Data
            ) {
                mailingRowClass = 'mailing-row';
                descriptionField = (
                    <div className="mailing-container">
                        <div className="mailing-select-field provider-field">
                            <div className="label">Provider:</div>
                            <select value={props.purchaseOrder.items[i].provider} onChange={handleUpdateItem.bind(this, i, 'provider')}>
                                <option value="" className="select-option">Select option</option>
                                <option value="CSI">CSI</option>
                                <option value="AccuData">AccuData</option>
                                <option value="Acxiom">Acxiom</option>
                                <option value="Alesco Data">Alesco Data</option>
                            </select>
                        </div>
                        <div className="mailing-select-field type-field">
                            <div className="label">Type:</div>
                            <select value={props.purchaseOrder.items[i].type} onChange={handleUpdateItem.bind(this, i, 'type')}>
                                <option value="" className="select-option">Select option</option>
                                <option value="ResList">ResList</option>
                                <option value="ResPlus">ResPlus</option>
                                <option value="Comsumer Data">Comsumer Data</option>
                            </select>
                        </div>
                        <div className="mailing-select-field geography-field">
                            <div className="label">Geography:</div>
                            <select value={props.purchaseOrder.items[i].geography} onChange={handleUpdateItem.bind(this, i, 'geography')}>
                                <option value="" className="select-option">Select option</option>
                                <option value="Houston">Houston</option>
                                <option value="Outside Houston">Outside Houston</option>
                            </select>
                        </div>
                        <div className="mailing-zip-field">
                            <div className="label">Zip:</div>
                            <input
                                type="text"
                                value={props.purchaseOrder.items[i].zip}
                                onChange={handleUpdatePrice.bind(this, i, 'zip')}
                            />
                        </div>
                    </div>
                );
            }

            itemsElements[i] = {
                departmentId: props.purchaseOrder.items[i].purchase_order_category_id,
                element: (
                    <tr key={i}>
                        {/* <td>{i + 1}</td> */}
                        <td className={mailingRowClass}>{props.purchaseOrder.items[i].sort_position}</td>
                        <td className={mailingRowClass}>
                            {upArrow}
                            {downArrow}
                        </td>
                        <td className={mailingRowClass}>
                            <select value={props.purchaseOrder.items[i].purchase_order_category_id} onChange={handleUpdateItemCategory.bind(this, i, 'purchase_order_category_id')}>
                                <option value="" className="select-option">Select option</option>
                                {renderCategoriesOptions()}
                            </select>
                        </td>
                        <td className={mailingRowClass}>
                            <select value={props.purchaseOrder.items[i].purchase_order_class_id} onChange={handleUpdateItemClass.bind(this, i, 'purchase_order_class_id')}>
                                <option value="">Select option</option>
                                {renderClassesOptions()}
                            </select>
                        </td>
                        <td>
                            {descriptionField}
                        </td>
                        <td className={`text-right ${mailingRowClass}`}>
                            <input
                                type="number"
                                value={props.purchaseOrder.items[i].quantity}
                                onChange={handleUpdatePrice.bind(this, i, 'quantity')}
                            />
                        </td>
                        <td className={`text-right ${mailingRowClass}`}>
                            <input
                                type="number"
                                value={props.purchaseOrder.items[i].unit_price}
                                onChange={handleUpdatePrice.bind(this, i, 'unit_price')}
                            />
                        </td>
                        <td className={`text-right ${mailingRowClass}`}>
                            <input
                                type="number"
                                value={props.purchaseOrder.items[i].amount}
                                onChange={handleUpdatePrice.bind(this, i, 'amount')}
                            />
                        </td>
                        <td className={`customer-th ${mailingRowClass}`}>
                            <select value={props.purchaseOrder.items[i].customer_id} onChange={handleUpdateItem.bind(this, i, 'customer_id')}>
                                <option value="" className="select-option">None</option>
                                {props.information.customers.map((customer) => {
                                    return <option value={customer.id}>{customer.display_name}</option>
                                })}
                            </select>
                        </td>
                        <td className={`"actions ${mailingRowClass}`}>
                            {deleteIcon}
                        </td>
                    </tr>
                )
            };
        }

        let itemLines = [];
        for (let i = 0; i < itemsElements.length; i++) {
            itemLines.push(itemsElements[i].element);
        }

        return itemLines;
    };

    let total = 0;
    if (props.purchaseOrder != null) {
        for (const item of props.purchaseOrder.items) {
            total += parseFloat(item.amount);
        }
    }

    let qBPushLabel = <Fragment>QuickBooks Push <i className="fas fa-file-upload" /></Fragment>;
    if (props.isPushing) {
        qBPushLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const isUserAllowed = [26, 35, 42, 48, 52, 53, 58, 59, 61, 63, 64, 75, 100, 348, 571, 28, 34, 245, 36, 78, 89, 1147, 31, 32, 33, 1180, 76, 34, 2419, 3153, 3566, 133].includes(parseInt(props.user.id));

    let qBPushButton = null;
    // Kimberly (26), Randy (35), Debbie (42), Skip (48), John (52), Kevin Wilson (53), Gary (58), Robert (59), Hugh (61), Norm (63), 
    // Christy (64), Kellie R. (75), Brenda (100), Grace (348), AJ (571), Heather (28), Heather (34), Nichole (245), Mike (36), Kellie A. (78),
    // April (89), AP (1147), Beatriz (31), Nyke (33), Rocio (32), Terri (2335), Edward (1180), Kathy (76), Lupe (34), Stephanie (2419), Paul (3153), 
    // Kassandra (3566), Kassandra (133)
    if (props.purchaseOrder != null && props.purchaseOrder.id != null && props.user != null &&
        ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting || isUserAllowed)) {
        qBPushButton = (
            <button className="action-button qb-button" onClick={props.showPushModal} disabled={props.isPushing}>
                {qBPushLabel}
            </button>
        );
    }

    let saveButtonLabel = <Fragment>Save <i className="fas fa-save" /></Fragment>;
    if (props.isSaving) {
        saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSavePurchaseOrder = () => {
        props.savePurchaseOrder(props.history);
    };

    let saveButton = null;
    // Kimberly (26), Randy (35), Debbie (42), Skip (48), John (52), Kevin Wilson (53), Gary (58), Robert (59), Hugh (61), Norm (63), 
    // Christy (64), Kellie R. (75), Brenda (100), Grace (348), AJ (571), Heather (28), Heather (34), Nichole (245), Mike (36), Kellie A. (78),
    // April (89), AP (1147), Beatriz (31), Nyke (33), Rocio (32), Terri (2335), Edward (1180), Kathy (76), Lupe (34), Stephanie (2419), Kassandra (3566)
    if ([1, 2, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting || isUserAllowed) {     // Approve
        saveButton = <button className="action-button save-button" onClick={handleSavePurchaseOrder} disabled={props.isSaving}>{saveButtonLabel}</button>;
    }

    const handlePreview = () => {
        if (!validateForm(props.purchaseOrder)) {
            return;
        }

        window.open(process.env.REACT_APP_API_URL + '/purchase-order/preview?id=' + props.purchaseOrder.id);
    };

    let previewButton = null;
    if (props.purchaseOrder != null && props.purchaseOrder.id != null) {
        previewButton = <button className="action-button" onClick={handlePreview}>Preview PDF <i className="fas fa-file-pdf" /></button>;
    }

    let qbNumber = 'N/A';
    if (props.purchaseOrder != null && props.purchaseOrder.qb_number != null) {
        qbNumber = props.purchaseOrder.qb_number;
    }

    const renderFieldValue = (field) => {
        let value = props.purchaseOrder[field];
        if (!props.purchaseOrder[field] || props.purchaseOrder[field] == null) {
            value = '';
        }

        return value
    };

    let logo = null;

    const logoInt = parseInt(props.purchaseOrder.logo);
    if (logoInt === 1) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/utp-logo-140524.png" alt="Logo" className="small-logo" />;
    } else if (logoInt === 2) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/uth-new-logo-low.png" alt="Logo" className="small-logo" />;
    } else if (logoInt === 3) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/uth-houston-logo.png" alt="Logo" className="small-logo" />;
    } else if (logoInt === 4) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/uth-cizik.logo.png" alt="Logo" />;
    } else if (logoInt === 5) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/uth-mcgovern-logo.png" alt="Logo" />;
    } else if (logoInt === 6) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/uth-biomedical-informatics-logo.png" alt="Logo" />;
    } else if (logoInt === 7) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/uth-dentistry-logo.png" alt="Logo" />;
    } else if (logoInt === 8) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/uth-public-health-logo.png" alt="Logo" />;
    } else if (logoInt === 9) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/school-of-public-health-austin.png" alt="Logo" />;
    } else if (logoInt === 10) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/school-of-public-health-brownsville.png" alt="Logo" />;
    } else if (logoInt === 11) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/school-of-public-health-dallas.png" alt="Logo" />;
    } else if (logoInt === 12) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/school-of-public-health-el-paso.png" alt="Logo" />;
    } else if (logoInt === 13) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/school-of-public-health-san-antonio.png" alt="Logo" />;
    } else if (logoInt === 15) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/uth-childrens-heart.png" alt="Logo" />;
    } else if (logoInt === 16) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/mcwilliams-logo-v82923.png" alt="Logo" />;
    } else if (logoInt === 17) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/UTHH-Neuroscience.png" alt="Logo" />;
    } else if (logoInt === 18) {
        logo = <img src="https://ac-ut-health.s3.amazonaws.com/css/UTHH-RobertCizikEyeClinic.png" alt="Logo" />;
    }

    return (
        <div className="purchase-order-form-container">
            <div className="fields-section">
                <div className="vendor-section">
                    <h4>Vendor</h4>
                    <div className="field">
                        <div className="label">Vendor:</div>
                        <select value={props.purchaseOrder.vendor_id} onChange={handleOnChange.bind(this, 'vendor_id')}>
                            <option value="">Select Vendor</option>
                            {props.information.vendors.map((vendor) => {
                                return <option value={vendor.id}>{vendor.display_name}</option>
                            })}
                        </select>
                    </div>
                    <div className="field">
                        <div className="label">Stagecoach No.:</div>
                        <input
                            type="text"
                            value={renderFieldValue('stagecoach_number')}
                            onChange={handleOnChange.bind(this, 'stagecoach_number')}
                        />
                    </div>
                    <div className="field">
                        <div className="label">QuickBooks Purchase Order No.:</div>
                        {qbNumber}
                    </div>
                    <div className="field">
                        <div className="label">Logo:</div>
                        <select value={props.purchaseOrder.logo} onChange={handleOnChange.bind(this, 'logo')}>
                            <option value="">None</option>
                            <option value="1">UT Physicians</option>
                            <option value="2">UTHealth Houston</option>
                            <option value="3">UTHealth Houston Heart and Vascular</option>
                            <option value="4">Cizik School of Nursing</option>
                            <option value="5">McGovern Medical School</option>
                            <option value="6">School of Biomedical Informatics</option>
                            <option value="7">School of Dentistry</option>
                            <option value="8">School of Public Health</option>
                            <option value="9">School of Public Health - Austin</option>
                            <option value="10">School of Public Health - Brownsville</option>
                            <option value="11">School of Public Health - Dallas</option>
                            <option value="12">School of Public Health - El Paso</option>
                            <option value="13">School of Public Health - San Antonio</option>
                            <option value="15">UTHealth Houston Children's Heart Institute</option>
                            <option value="16">McWilliams School of Biomedical Informatics</option>
                            <option value="17">UTHealth Houston Neurosciences</option>
                            <option value="18">UTHealth Houston Cizik Eye Clinic</option>
                        </select>
                        <div className="logo-image">
                            {logo}
                        </div>
                    </div>
                </div>
                <div className="billing-section">
                    <h4>
                        <div>Address</div>
                    </h4>
                    <div className="fields">
                        <div className="field">
                            <div className="label">Vendor Email:</div>
                            <input
                                type="text"
                                placeholder="Separate emails with commas"
                                value={renderFieldValue('vendor_email')}
                                onChange={handleOnChange.bind(this, 'vendor_email')}
                            />
                        </div>
                        <div className="field">
                            <div className="label">Name:</div>
                            <input type="text" value={renderFieldValue('vendor_name')} onChange={handleOnChange.bind(this, 'vendor_name')} />
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            <input type="text" value={renderFieldValue('vendor_address')} onChange={handleOnChange.bind(this, 'vendor_address')} />
                        </div>
                        <div className="field">
                            <div className="label">City:</div>
                            <input type="text" value={renderFieldValue('vendor_city')} onChange={handleOnChange.bind(this, 'vendor_city')} />
                        </div>
                        <div className="field">
                            <div className="label">State:</div>
                            <input type="text" value={renderFieldValue('vendor_state')} onChange={handleOnChange.bind(this, 'vendor_state')} />
                        </div>
                        <div className="field">
                            <div className="label">Zip:</div>
                            <input type="text" value={renderFieldValue('vendor_zip')} onChange={handleOnChange.bind(this, 'vendor_zip')} />
                        </div>
                    </div>
                </div>
                <div className="shipping-section">
                    <h4>
                        <div>Notes</div>
                    </h4>
                    <div className="fields">
                        <div className="field">
                            <div className="label">Your message to the vendor:</div>
                            <textarea value={props.purchaseOrder.message} onChange={handleOnChange.bind(this, 'message')} />
                        </div>
                    </div>
                    <div className="fields">
                        <div className="field">
                            <div className="label">Memo:</div>
                            <textarea value={props.purchaseOrder.memo} onChange={handleOnChange.bind(this, 'memo')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="items-list">
                <h4>
                    <div className="title-column">
                        <div>Items</div>
                    </div>
                </h4>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th className="number-th">#</th>
                            <th className="sort-th"></th>
                            <th className="department-th">Category</th>
                            <th className="task-th">Class</th>
                            <th className="description-th">Description</th>
                            <th className="quantity-th">Qty</th>
                            <th className="rate-th">Rate</th>
                            <th className="amount-th text-right">Amount</th>
                            <th className="customer-th">Customer</th>
                            <th className="actions-th"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItems()}
                    </tbody>
                </table>
                <div className="bottom-container">
                    <div className="items-buttons-container">
                        <div className="button-field-container">
                            <div className="button-container">
                                <button className="action-button" onClick={props.addLine}>Add Line <i className="fas fa-plus" /></button>
                            </div>
                        </div>
                        <div className="audit-history-container">
                            <div className="title">Audit Log:</div>
                            <div className="log">
                                {props.purchaseOrder.auditHistory.map((record, index) => (
                                    <div className="log-element" key={'log-element-' + index}>
                                        <b className={record.is_update ? 'pink-value' : ''}>{dateToTimezone(record.created_at, 'MMMM Do YYYY, h:mm a')}:</b> <span className="label" dangerouslySetInnerHTML={{ __html: record.label }}></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="totals-container">
                        <div className="total-item total">
                            <div className="label">Total:</div>
                            <div className="value">{numeral(total).format('$0,0.00')}</div>
                        </div>
                        <div className="buttons-container">
                            {qBPushButton}
                            {previewButton}
                            {saveButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Form;
