import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Chat from './../../Chat';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

const Header = props => {
    let url = null;
    const pqtType = props.user !== null && parseInt(props.user.pqt_type);

    if ([7].includes(pqtType)) {
        url = '/department/' + props.user.department_id;
    } else if ([3].includes(pqtType)) {
        url = '/dashboard';
    } else if ([6].includes(pqtType)) {
        url = '/department/' + props.user.pqt_department;
    } else {
        url = '/dashboard';
    }

    let userName = props.user.name;
    if (props.user.name != null) {
        let userNameParts = props.user.name.split(' ');
        if (userNameParts.length > 0) {
            userName = userNameParts[0];
        }
    }

    let invoicesLink = null;
    if (props.user != null && ([1, 2, 3, 4, 5, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting) && ![76].includes(parseInt(props.user.id))) {
        invoicesLink = (
            <Fragment>
                <Link to="/invoices">Invoices</Link> |&nbsp;
            </Fragment>
        );
    }

    let postageInvoicesLink = null;
    if (props.user != null && ([1, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
        postageInvoicesLink = (
            <Fragment>
                <Link to="/postage-invoices">Postage Invoices</Link> |&nbsp;
            </Fragment>
        );
    }

    const handlePostageEscrow = () => {
        props.fetchCustomers();
        props.showQuickPostageEscrowModal();
    };

    const handleSalesReportIcon = () => {
        props.fetchCustomers();
        props.setActiveTab(2);
        props.showQuickPostageEscrowModal();
    };

    const handleCommissionsReportIcon = () => {
        props.fetchCustomers();
        props.setActiveTab(3);
        props.showQuickPostageEscrowModal();
    };

    const handleShippingReportIcon = () => {
        props.setActiveTab(6);
        props.showQuickPostageEscrowModal();
    };

    const handleTaskRequestIcon = () => {
        props.createTaskRequest();
    };

    let postageEscrowLink = null;
    let poLink = null;
    let deletedInvoicesLink = null;
    let deletedJobsLink = null;
    let rfqsLink = null;
    let helpItemsLink = null;
    let creditMemosLink = null;
    let onHoldCustomersLink = null;
    let compensationsLink = null;
    let usersLink = null;
    let mailingLink = null;
    let apDashboardLink = null;
    if (props.user != null && parseInt(props.user.id) === 61) {     // Hugh
        rfqsLink = (
            <Fragment>
                <Link to="/requests-for-quotes">RFQs</Link> |&nbsp;
            </Fragment>
        );
        postageEscrowLink = (
            <Fragment>
                <Link to="#" onClick={handlePostageEscrow}>Quick Check</Link> |&nbsp;
            </Fragment>
        );
        poLink = (
            <Fragment>
                <Link to="/purchase-orders">POs</Link> |&nbsp;
            </Fragment>
        );
        deletedInvoicesLink = (
            <Fragment>
                <Link to="/deleted-invoices">Deleted Invoices</Link> |&nbsp;
            </Fragment>
        );
        deletedJobsLink = (
            <Fragment>
                <Link to="/deleted-jobs">Deleted Jobs</Link> |&nbsp;
            </Fragment>
        );
        helpItemsLink = (
            <Fragment>
                <Link to="/help-items">Help Items</Link> |&nbsp;
            </Fragment>
        );
        creditMemosLink = (
            <Fragment>
                <Link to="/credit-memos">Credit Memos</Link> |&nbsp;
            </Fragment>
        );
        onHoldCustomersLink = (
            <Fragment>
                <Link to="/on-hold-customers">On Hold Customers</Link> |&nbsp;
            </Fragment>
        );
        compensationsLink = (
            <Fragment>
                <Link to="/commission-compensations">Compensations</Link> |&nbsp;
            </Fragment>
        );
        usersLink = (
            <Fragment>
                <Link to="/users">Users</Link>
            </Fragment>
        );
        mailingLink = (
            <Fragment>
                <Link to="/mailing-categories">Mailing Pricing</Link> |&nbsp;
            </Fragment>
        );
        apDashboardLink = (
            <Fragment>
                <Link to="/ap-dashboard">AP Dashboard</Link> |&nbsp;
            </Fragment>
        );
    }
    if (props.user != null && [26, 63, 245, 34, 28, 2419].includes(parseInt(props.user.id))) {     // Kimberly (26), Norm (63), Nichole (245), Lupe (34), Heather (28), Stephanie (2419)
        onHoldCustomersLink = (
            <Fragment>
                <Link to="/on-hold-customers">On Hold Customers</Link> |&nbsp;
            </Fragment>
        );
    }
    if (props.user != null && ([36, 52, 58, 59, 63, 3153].includes(parseInt(props.user.id)) || [1, 2, 3, 4, 5, 9].includes(parseInt(props.user.pqt_type)))) {     // Mike, John, Gary, Robert, Norm, Paul
        rfqsLink = (
            <Fragment>
                <Link to="/requests-for-quotes">RFQs</Link> |&nbsp;
            </Fragment>
        );
    }

    // Kimberly (26), Randy (35), Debbie (42), Skip (48), John (52), Kevin Wilson (53), Gary (58), Robert (59), Hugh (61), Norm (63), 
    // Christy (64), Kellie R. (75), Brenda (100), Grace (348), AJ (571), Heather (28), Heather (34), Nichole (245), Mike (36), Kellie A. (78),
    // April (89), AP (1147), Beatriz (31), Nyke (33), Rocio (32), Terri (2335), Edward (1180), Kathy (76), Lupe (34), Stephanie (2419), Paul (3153), 
    // Kassandra (3566), Robert Duncan (3695), Adriana (133)
    if (props.user != null && [26, 35, 42, 48, 52, 53, 58, 59, 61, 63, 64, 75, 100, 348, 571, 28, 34, 245, 36, 78, 89, 1147, 31, 32, 33, 1180, 76, 2335, 34, 2419, 3153, 3566, 3695, 133].includes(parseInt(props.user.id))) {
        poLink = (
            <Fragment>
                <Link to="/purchase-orders">POs</Link> |&nbsp;
            </Fragment>
        );
    }

    let christyRfqLink = null;
    if (props.user && props.user != null && [1, 2].includes(props.user.christy_rfq_type)) {
        christyRfqLink = (
            <Fragment>
                <Link to="/customer-rfqs">Customer RFQs</Link> |&nbsp;
            </Fragment>
        );
    }

    let apRecordsLink = null;
    if (props.user != null && [61, 1147, 2419].includes(parseInt(props.user.id))) {     // Hugh (61), AP (1147), Stephanie (2419)
        apRecordsLink = (
            <Fragment>
                <Link to="/ap-records">AP Records</Link> |&nbsp;
            </Fragment>
        );
    }

    let apAccountsLink = null;
    if (props.user != null && [61].includes(parseInt(props.user.id))) {     // Hugh (61)
        apAccountsLink = (
            <Fragment>
                <Link to="/ap-accounts">AP Accounts</Link> |&nbsp;
            </Fragment>
        );
    }

    let estimateIcon = null;
    if (props.user != null && ([36, 52, 58, 59, 61, 63, 3153].includes(parseInt(props.user.id)) ||     // Mike, John, Gary, Robert, Hugh, Norm, Paul
        [1, 2, 3, 4, 5, 9].includes(parseInt(props.user.pqt_type)) || props.user.is_pqt_accounting)) {
        estimateIcon = (
            <div className="estimate-icon">
                <Tooltip title="Estimates" position="bottom" arrow size="small" className="action-tooltip">
                    <Link to="/estimates"><img src={require('./../images/abacus.png')} alt="Estimate" /></Link>
                </Tooltip>
            </div>
        );
    }

    let salesReportIcon = null;
    if (props.user != null && [61, 63].includes(parseInt(props.user.id))) {     // Hugh, Norm
        salesReportIcon = (
            <div className="sales-report-icon">
                <Tooltip title="Sales Report" position="bottom" arrow size="small" className="action-tooltip">
                    <Link to="#" onClick={handleSalesReportIcon}><img src={require('./../images/sales-report-icon.png')} alt="Sales Report" /></Link>
                </Tooltip>
            </div>
        );
    }

    let commissionsIcon = null;
    if (props.user != null && [61].includes(parseInt(props.user.id))) {     // Hugh
        commissionsIcon = (
            <div className="commissions-report-icon">
                <Tooltip title="Commissions Report" position="bottom" arrow size="small" className="action-tooltip">
                    <Link to="#" onClick={handleCommissionsReportIcon}><img src={require('./../images/Sales-Commission-Icon.jpg')} alt="Commissions" /></Link>
                </Tooltip>
            </div>
        );
    }

    let payrollIcon = null;
    if (props.user != null && [61, 245, 401, 2419].includes(parseInt(props.user.id))) {     // Hugh (61), Nichole (245), Sandy (401), Stephanie (2419)
        payrollIcon = (
            <div className="payroll-icon">
                <Tooltip title="Payroll" position="bottom" arrow size="small" className="action-tooltip">
                    <Link to="/hrp-payroll-reports"><img src={require('./../images/Payroll-icon.jpg')} alt="Payroll" /></Link>
                </Tooltip>
            </div>
        );
    }

    let shippingIcon = null;
    if (props.user != null && [28, 34, 61, 63, 64, 1147, 2419].includes(parseInt(props.user.id))) {     // Heather (28), Lupe (34), Hugh (61), Norm (63), Christy (64), Amy (1147), Stephanie (2419)
        shippingIcon = (
            <div className="ap-shipping-icon">
                <Tooltip title="AP Shipping" position="bottom" arrow size="small" className="action-tooltip">
                    <Link to="#" onClick={handleShippingReportIcon}><img src={require('./../images/ap-shipping.png')} alt="Shipping" /></Link>
                </Tooltip>
            </div>
        );
    }

    let callToActionIcon = null;
    if (props.user != null && [28, 34, 61, 63, 64, 245, 401, 2419].includes(parseInt(props.user.id))) {     // Heather (28), Lupe (34), Hugh (61), Norm (63), Christy (64), Nichole (245), Sandy (401), Stephanie (2419)
        callToActionIcon = (
            <div className="call-to-action-icon">
                <Tooltip title="Call to action" position="bottom" arrow size="small" className="action-tooltip">
                    <a href={process.env.REACT_APP_API_URL + '/invoice/call-to-action-report'}>
                        <img src={require('./../images/call-to-action-icon.png')} alt="Call to action" />
                    </a>
                </Tooltip>
            </div>
        );
    }

    if (props.user != null && parseInt(props.user.id) === 63) {     // Norm
        helpItemsLink = (
            <Fragment>
                <Link to="/help-items">Help Items</Link> |&nbsp;
            </Fragment>
        );
    }

    if (props.user != null && [3566].includes(parseInt(props.user.id))) {     // Hugh (61), Kassandra (3566)
        apDashboardLink = (
            <Fragment>
                <Link to="/ap-dashboard">AP Dashboard</Link> |&nbsp;
            </Fragment>
        );
    }
    
    let taskRequestIcon = null;
    let taskRequestLoading = null;
    if (props.creatingTask) {
        taskRequestLoading = <i className="fas fa-circle-notch fa-spin" />;
    }

    taskRequestIcon = (
        <div className="task-request-icon">
            {taskRequestLoading}
            <Tooltip title="Task Request" position="bottom" arrow size="small" className="action-tooltip">
                <Link to="#" onClick={handleTaskRequestIcon}><img src="https://ac-gdp-request.s3.us-east-2.amazonaws.com/icon.png" alt="Task Request" /></Link>
            </Tooltip>
        </div>
    );

    return (
        <div className="header-container">
            <div className="logo"><Link to={url}><img src={require('./../images/stagecoach-logo.png')} alt="Stagecoach" /></Link></div>
            <div className="title">
                {props.title}
            </div>
            {pqtType !== 7 && <Chat newVersion={props.newVersion} />}
            <div className="menu">
                <div className="welcome"><i className="fas fa-user" /> Welcome back <b>{userName}</b></div>
                <div>
                    {apAccountsLink}
                    {apDashboardLink}
                    {apRecordsLink}
                    {compensationsLink}
                    {creditMemosLink}
                    {christyRfqLink}
                    <Link to={url}>Dashboard</Link> |&nbsp;
                    {deletedInvoicesLink}
                    {deletedJobsLink}
                    {helpItemsLink}
                    {invoicesLink}
                    {mailingLink}
                    {onHoldCustomersLink}
                    {poLink}
                    {postageInvoicesLink}
                    {postageEscrowLink}
                    {rfqsLink}
                    <Link to="/search">Search</Link> |&nbsp;
                    <Link to="#" onClick={props.logoutHandler}>Sign Out</Link> |&nbsp;
                    {usersLink}
                </div>
                <div className="icons-container">
                    <Tooltip title="Make a Payment Page" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to="/make-payment" className="no-underline">
                            <i className="make-payment-icon fas fa-credit-card"></i>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Credit Card Payment Authorization Form" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to="/cc-payment-authorization" className="no-underline">
                            <i className="cc-payment-icon fas fa-file-invoice-dollar"></i>
                        </Link>
                    </Tooltip>
                    {taskRequestIcon}
                    {shippingIcon}
                    {callToActionIcon}
                    <div className="calculator-icon">
                        <Tooltip title="Calculator" position="bottom" arrow size="small" className="action-tooltip">
                            <Link to="/markup-calculator"><img src={require('./../images/calculator-icon.png')} alt="Calculator" /></Link>
                        </Tooltip>
                    </div>
                    {payrollIcon}
                    {commissionsIcon}
                    {salesReportIcon}
                    {estimateIcon}
                    {props.actionButtons}
                </div>
            </div>
        </div>
    );
}

export default Header;
